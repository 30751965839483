import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import Header from "../../components/Header";
import { Viewer } from "@react-pdf-viewer/core";

import pdf from "../../assets/compropdf.pdf";
import comproCover from "../../assets/compro_cover.jpg";
import Button from "../../components/Button";

export default function CompanyProfile() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const url = window.URL.createObjectURL(new Blob([pdf], { type: "application/pdf" }));
    }, []);

    return (
        <div>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="">
                    <Header
                        bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                        className="h-[5.3rem] max-md:h-[4rem] xl:px-32 md:px-14 px-10"
                    >
                        <div className="text-4xl space-y-1">
                            <div className="absolute w-full top-0 bg-gradient-to-r from-blue-950/100 to-blue-300/5 z-10"></div>
                        </div>
                    </Header>
                    <div className="md:py-10">
                        <div className="h-screen max-md:hidden">
                            <iframe src={pdf} type="application/pdf" className="h-full w-full xl:px-32 lg:px-24 md:px-16 px-5 md:py-14 py-0" />
                        </div>
                        <div className="md:hidden">
                            <div className="bg-gradient-to-r from-[#073067] to-[#1E6BCF] text-text_40 xl:px-32 lg:px-24 md:px-16 px-5 md:py-14 py-10">
                                <p className="font-light">PT Cliste Rekayasa Indonesia</p>
                                <h1>Company Profile</h1>
                            </div>
                            <a href={comproCover}>
                                <img src={comproCover} className="xl:px-32 lg:px-24 md:px-16 px-5 md:py-14 py-10" alt="" />
                            </a>
                        </div>
                        <div className="flex justify-center items-center w-full py-10">
                            <a href={pdf} download="CompanyProfileCRI.pdf">
                                <Button title={"Download PDF"} status={"primary"} />
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
