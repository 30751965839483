import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import clisteLogo from "../assets/cliste-logo.png";
import { useMediaQuery } from "react-responsive";
import { FaBars, FaSearch, FaTimes } from "react-icons/fa"; // Icon for hamburger and close menu
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

export default function NavBar() {
    const [language, setLanguage] = useState("EN");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const location = useLocation();
    const [activePage, setActivePage] = useState("");
    const isMobile = useMediaQuery({ maxWidth: 899 });
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const menuItems = [
        { name: "Overview", key: "overview", path: "/" },
        { name: "Services", key: "services", path: "/services" },
        { name: "About Us", key: "aboutUs", path: "/aboutus" },
        { name: "Our Insight", key: "OurInsight", path: "/ourinsight" },
        { name: "Career", key: "career", path: "/career" },
        { name: "Contact Us", key: "contactus", path: "/contactus" },
    ];

    useEffect(() => {
        const currentItem = menuItems.find((item) => item.path === location.pathname);
        if (currentItem) {
            setActivePage(currentItem.key);
        }
    }, [location.pathname, menuItems]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
        setDropdownOpen(false); // Tutup dropdown setelah memilih bahasa
    };

    const toggleSearch = () => {
        setSearchActive(!searchActive); // Toggle state search
    };

    return (
        <div className="absolute  bg-gradient-to-b from-black/65 to-transparent w-full flex items-center justify-center py-3 px-6 md:px-10 text-white z-50">
            <div className="flex justify-center max-lg:justify-between items-center w-full max-w-[1920px]">
                <div>
                    <a href="/">
                        <svg width="128" height="52.54" viewBox="0 0 192 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M37.5898 38.5963V38.5985L18.3455 58.3922C17.7803 56.4524 16.6497 54.6376 14.9628 53.1785C14.0633 52.3992 13.0674 51.7766 12.0175 51.3086C12.0153 51.3065 12.0131 51.3065 12.0086 51.3065C11.7439 51.1879 11.4747 51.0799 11.201 50.9825L11.1988 50.9804C10.7838 50.8046 10.3733 50.6119 9.96728 50.4044C8.62138 49.7161 7.34053 48.8606 6.16286 47.8378C4.27186 46.1987 2.81155 44.2822 1.78866 42.2154C-1.3787 35.8201 -0.360297 27.9784 5.00987 22.4535L26.8382 0L26.8539 0.0127054C31.6386 4.15907 31.9594 11.1833 27.565 15.7003L14.0543 29.5963C13.9242 29.7128 13.7964 29.8335 13.673 29.9606C13.5496 30.0876 13.433 30.2168 13.3231 30.3502C10.9834 33.1434 11.2908 37.2114 14.1149 39.6573C15.416 40.7839 17.031 41.3789 18.6663 41.4552L36.854 22.7478C36.8876 22.7754 36.919 22.8029 36.9504 22.8304C41.7351 26.9768 42.0559 34.001 37.6638 38.518C37.6413 38.5455 37.6167 38.5709 37.5898 38.5963Z"
                                fill="white"
                            />
                            <path
                                d="M55.5066 52.9933L41.996 66.8894C41.8659 67.0059 41.738 67.1266 41.6146 67.2536C41.4913 67.3807 41.3746 67.5099 41.2647 67.6433L30.7487 78.4602L30.733 78.4475C29.7415 77.5878 28.9407 76.6031 28.335 75.5442C26.0178 71.4953 26.5405 66.3409 30.0197 62.76C30.0197 62.76 47.1306 45.1771 47.2697 45.0161C49.8605 42.0366 50.5492 38.1189 49.3962 34.646C51.3433 35.0526 53.2029 35.9335 54.7776 37.2931L54.791 37.3058C59.5802 41.4521 59.8988 48.4764 55.5066 52.9933Z"
                                fill="white"
                            />
                            <path
                                d="M54.7742 21.2406C54.0811 22.6213 50.3956 25.7427 50.3956 25.7427C49.7787 25.3827 49.1887 24.9613 48.6347 24.4827C45.723 21.9606 43.3767 18.0789 44.9177 14.93C45.6198 13.4963 49.4422 10.2754 49.4422 10.2754C49.7787 10.4766 50.1062 10.6968 50.4247 10.934L51.7056 12.0436C54.2314 14.5785 56.266 18.2717 54.7742 21.2406Z"
                                fill="white"
                            />
                            <path
                                d="M78.9541 17.8325C78.9541 12.5781 83.1591 9.15723 88.1697 9.15723C90.6124 9.15723 93.5322 9.96446 95.4222 12.8168L92.9039 14.4559C91.7464 12.8882 90.0076 12.2459 88.2714 12.2459C85.0987 12.2459 82.4787 14.4092 82.4787 17.8325C82.4787 21.2805 85.1222 23.4192 88.2948 23.4192C90.0832 23.4192 91.7438 22.7768 92.9039 21.2091L95.4222 22.8482C93.5348 25.7006 90.6124 26.5078 88.1697 26.5078C83.1357 26.5103 78.9541 23.0869 78.9541 17.8325Z"
                                fill="white"
                            />
                            <path d="M97.4609 9.39355H100.834V26.2716H97.4609V9.39355Z" fill="white" />
                            <path
                                d="M103.405 9.39355H106.779V12.1992H103.405V9.39355ZM103.405 13.9121H106.779V26.2741H103.405V13.9121Z"
                                fill="white"
                            />
                            <path
                                d="M108.589 23.0407L111.284 22.3516C111.537 23.1588 112.166 23.9907 113.451 23.9907C114.306 23.9907 115.138 23.587 115.138 22.706C115.138 22.1129 114.736 21.6846 113.701 21.3278L112.09 20.7568C109.924 20.0209 109.019 18.7117 109.019 17.2153C109.019 15.0053 111.084 13.6738 113.553 13.6738C115.92 13.6738 117.656 14.9339 118.086 16.8363L115.443 17.4787C115.091 16.4795 114.41 16.1718 113.706 16.1718C112.825 16.1718 112.296 16.6468 112.296 17.2424C112.296 17.7641 112.622 18.217 113.657 18.5492L115.117 19.0734C116.728 19.6198 118.49 20.523 118.49 22.7109C118.49 25.1843 116.248 26.5133 113.506 26.5133C110.906 26.5108 108.993 25.3221 108.589 23.0407Z"
                                fill="white"
                            />
                            <path
                                d="M121.384 22.8467V16.4528H119.52V13.9105H121.384V10.748H124.783V13.9105H127.476V16.4528H124.783V22.3249C124.783 22.9673 125.161 23.585 126.269 23.585C126.571 23.585 127.025 23.5383 127.502 23.3955V25.8443C126.999 26.2725 126.042 26.4152 125.211 26.4152C122.995 26.4128 121.384 25.3914 121.384 22.8467Z"
                                fill="white"
                            />
                            <path
                                d="M128.784 20.0923C128.784 15.9331 132.059 13.6738 135.406 13.6738C138.73 13.6738 142.507 15.8839 141.777 21.3278H131.983C132.463 23.0161 134.022 23.8947 135.987 23.8947C137.272 23.8947 138.404 23.5624 139.361 22.7774L140.896 24.3229C139.814 25.6076 137.924 26.5108 135.609 26.5108C132.009 26.5108 128.784 24.2048 128.784 20.0923ZM138.782 18.9971C138.581 17.2375 137.02 16.3589 135.432 16.3589C133.87 16.3589 132.361 17.2153 131.931 18.9971H138.782Z"
                                fill="white"
                            />
                            <path d="M79.208 52.3169H82.657V69.195H79.208V52.3169Z" fill="white" />
                            <path
                                d="M85.1992 56.8334H88.5726V58.5217C89.3026 57.429 90.6113 56.5972 92.5769 56.5972C95.2725 56.5972 97.5379 58.1649 97.5379 61.9229V69.1979H94.1645V62.4471C94.1645 60.6407 93.1322 59.452 91.3699 59.452C89.6336 59.452 88.5752 60.6407 88.5752 62.4471V69.1979H85.2018V56.8334H85.1992Z"
                                fill="white"
                            />
                            <path
                                d="M110.028 69.1925V67.5288H109.952C109.626 68.2179 108.114 69.4312 105.773 69.4312C102.726 69.4312 99.4775 67.2925 99.4775 63.0127C99.4775 58.7329 102.726 56.5942 105.773 56.5942C108.114 56.5942 109.626 57.8076 109.952 58.4967H110.028V52.3169H113.401V69.195H110.028V69.1925ZM106.451 66.5788C108.339 66.5788 110.101 65.3434 110.101 63.0127C110.101 60.6821 108.339 59.4466 106.451 59.4466C104.564 59.4466 102.799 60.7067 102.799 63.0127C102.801 65.3188 104.564 66.5788 106.451 66.5788Z"
                                fill="white"
                            />
                            <path
                                d="M115.34 63.0127C115.34 58.8289 118.815 56.5942 122.24 56.5942C125.64 56.5942 129.138 58.8289 129.138 63.0127C129.138 67.1966 125.637 69.4312 122.24 69.4312C118.815 69.4312 115.34 67.1966 115.34 63.0127ZM122.238 66.5542C124.125 66.5542 125.789 65.2228 125.789 63.0127C125.789 60.8027 124.128 59.4712 122.238 59.4712C120.35 59.4712 118.661 60.8027 118.661 63.0127C118.664 65.2228 120.35 66.5542 122.238 66.5542Z"
                                fill="white"
                            />
                            <path
                                d="M131.075 56.8334H134.449V58.5217C135.179 57.429 136.487 56.5972 138.453 56.5972C141.148 56.5972 143.414 58.1649 143.414 61.9229V69.1979H140.041V62.4471C140.041 60.6407 139.008 59.452 137.246 59.452C135.51 59.452 134.451 60.6407 134.451 62.4471V69.1979H131.078V56.8334H131.075Z"
                                fill="white"
                            />
                            <path
                                d="M145.352 63.0127C145.352 58.8535 148.626 56.5942 151.973 56.5942C155.297 56.5942 159.075 58.8043 158.345 64.2482H148.55C149.03 65.9365 150.589 66.8151 152.555 66.8151C153.84 66.8151 154.971 66.4828 155.928 65.6978L157.463 67.2433C156.382 68.528 154.492 69.4312 152.177 69.4312C148.576 69.4312 145.352 67.1252 145.352 63.0127ZM155.349 61.92C155.148 60.1603 153.587 59.2817 151.999 59.2817C150.438 59.2817 148.928 60.1382 148.498 61.92H155.349Z"
                                fill="white"
                            />
                            <path
                                d="M159.226 65.9611L161.921 65.272C162.174 66.0792 162.802 66.9111 164.088 66.9111C164.943 66.9111 165.774 66.5075 165.774 65.6264C165.774 65.0308 165.373 64.6051 164.338 64.2482L162.727 63.6772C160.56 62.9414 159.656 61.6321 159.656 60.1357C159.656 57.9257 161.72 56.5942 164.189 56.5942C166.556 56.5942 168.293 57.8543 168.723 59.7543L166.079 60.3966C165.727 59.3974 165.047 59.0898 164.343 59.0898C163.462 59.0898 162.933 59.5648 162.933 60.1603C162.933 60.6821 163.259 61.1349 164.294 61.4672L165.753 61.9889C167.364 62.5353 169.127 63.4385 169.127 65.6264C169.127 68.0973 166.885 69.4288 164.142 69.4288C161.543 69.4312 159.63 68.2425 159.226 65.9611Z"
                                fill="white"
                            />
                            <path d="M171.034 52.3169H174.408V55.1225H171.034V52.3169ZM171.034 56.833H174.408V69.195H171.034V56.833Z" fill="white" />
                            <path
                                d="M176.349 63.0127C176.349 58.7329 179.597 56.5942 182.644 56.5942C184.985 56.5942 186.498 57.8076 186.823 58.4967H186.899V56.833H190.272V69.195H186.899V67.5313H186.823C186.498 68.2204 184.985 69.4337 182.644 69.4337C179.597 69.4312 176.349 67.2925 176.349 63.0127ZM183.322 66.5788C185.21 66.5788 186.972 65.3434 186.972 63.0127C186.972 60.6821 185.21 59.4466 183.322 59.4466C181.435 59.4466 179.67 60.7067 179.67 63.0127C179.672 65.3188 181.435 66.5788 183.322 66.5788Z"
                                fill="white"
                            />
                            <path
                                d="M85.3265 42.3275H82.657V47.9142H79.208V31.0361H85.4777C89.2787 31.0361 91.797 33.3175 91.797 36.7655C91.797 39.1897 90.5639 40.9493 88.4992 41.7812L92.6781 47.9142H89.0023L85.3265 42.3275ZM85.1258 39.4998C87.0132 39.4998 88.2985 38.7393 88.2985 36.7655C88.2985 34.8163 87.0132 34.0313 85.1258 34.0313H82.657V39.4998H85.1258Z"
                                fill="white"
                            />
                            <path
                                d="M92.9541 41.7344C92.9541 37.5752 96.2284 35.3159 99.5758 35.3159C102.9 35.3159 106.677 37.526 105.947 42.9699H96.1528C96.6325 44.6582 98.1915 45.5368 100.157 45.5368C101.442 45.5368 102.574 45.2045 103.531 44.4194L105.066 45.965C103.984 47.2497 102.094 48.1529 99.7791 48.1529C96.1789 48.1529 92.9541 45.8469 92.9541 41.7344ZM102.952 40.6417C102.751 38.882 101.189 38.0034 99.6018 38.0034C98.0403 38.0034 96.5308 38.8599 96.1007 40.6417H102.952Z"
                                fill="white"
                            />
                            <path
                                d="M107.963 31.0361H111.336V40.8066L116.245 35.5522H120.273L115.061 40.9961L120.851 47.9142H117.074L112.971 43.1594L111.333 44.8698V47.9117H107.96V31.0361H107.963Z"
                                fill="white"
                            />
                            <path
                                d="M120.504 41.7344C120.504 37.4546 123.752 35.3159 126.8 35.3159C129.141 35.3159 130.653 36.5292 130.979 37.2183H131.054V35.5546H134.428V47.9166H131.054V46.2529H130.979C130.653 46.942 129.141 48.1554 126.8 48.1554C123.752 48.1529 120.504 46.0142 120.504 41.7344ZM127.478 45.3005C129.365 45.3005 131.127 44.065 131.127 41.7344C131.127 39.4038 129.365 38.1683 127.478 38.1683C125.59 38.1683 123.825 39.4284 123.825 41.7344C123.828 44.0404 125.59 45.3005 127.478 45.3005Z"
                                fill="white"
                            />
                            <path
                                d="M141.301 47.7971L135.863 35.5557H139.312L142.939 44.1374L146.539 35.5557H150.014L144.5 47.9177L142.459 52.4313H139.161L141.301 47.7971Z"
                                fill="white"
                            />
                            <path
                                d="M150.312 41.7344C150.312 37.4546 153.561 35.3159 156.608 35.3159C158.949 35.3159 160.461 36.5292 160.787 37.2183H160.863V35.5546H164.236V47.9166H160.863V46.2529H160.787C160.461 46.942 158.949 48.1554 156.608 48.1554C153.561 48.1529 150.312 46.0142 150.312 41.7344ZM157.289 45.3005C159.176 45.3005 160.938 44.065 160.938 41.7344C160.938 39.4038 159.176 38.1683 157.289 38.1683C155.401 38.1683 153.636 39.4284 153.636 41.7344C153.636 44.0404 155.399 45.3005 157.289 45.3005Z"
                                fill="white"
                            />
                            <path
                                d="M166.052 44.6828L168.747 43.9937C169 44.8009 169.629 45.6328 170.914 45.6328C171.769 45.6328 172.6 45.2291 172.6 44.3481C172.6 43.7525 172.199 43.3267 171.164 42.9699L169.553 42.3989C167.387 41.663 166.482 40.3537 166.482 38.8574C166.482 36.6474 168.547 35.3159 171.015 35.3159C173.383 35.3159 175.119 36.576 175.549 38.4784L172.905 39.1207C172.554 38.1215 171.873 37.8139 171.169 37.8139C170.288 37.8139 169.759 38.2889 169.759 38.8845C169.759 39.4062 170.085 39.8591 171.12 40.1913L172.58 40.7131C174.191 41.2594 175.953 42.1626 175.953 44.3505C175.953 46.8215 173.711 48.1529 170.968 48.1529C168.367 48.1529 166.453 46.9642 166.052 44.6828Z"
                                fill="white"
                            />
                            <path
                                d="M177.23 41.7344C177.23 37.4546 180.479 35.3159 183.526 35.3159C185.867 35.3159 187.379 36.5292 187.705 37.2183H187.781V35.5546H191.154V47.9166H187.781V46.2529H187.705C187.379 46.942 185.867 48.1554 183.526 48.1554C180.479 48.1529 177.23 46.0142 177.23 41.7344ZM184.207 45.3005C186.094 45.3005 187.856 44.065 187.856 41.7344C187.856 39.4038 186.094 38.1683 184.207 38.1683C182.319 38.1683 180.554 39.4284 180.554 41.7344C180.554 44.0404 182.317 45.3005 184.207 45.3005Z"
                                fill="white"
                            />
                        </svg>
                    </a>
                </div>

                {isMobile ? (
                    <button onClick={toggleMenu} className="text-white focus:outline-none z-30">
                        {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                    </button>
                ) : null}

                <div
                    className={`${
                        isMobile
                            ? `fixed inset-0 bg-black bg-opacity-90 z-20 flex flex-col items-center justify-center space-y-6 transform ${
                                  isMenuOpen ? "translate-x-0" : "-translate-x-full"
                              } transition-transform duration-300`
                            : "flex lg:gap-2 md:gap-1 lg:text-sm md:text-xs font-medium flex-1 justify-start lg:px-10 md:px-3 "
                    }`}
                >
                    {menuItems.map((item) => (
                        <Link
                            to={item.path}
                            key={item.key}
                            onClick={() => {
                                setActivePage(item.key);
                                setIsMenuOpen(false);
                            }}
                            className={`px-4 py-2 rounded duration-300 ease-in-out cursor-pointer hover:text-white hover:bg-[#4a7cae] hover:rounded-lg ${
                                activePage === item.key
                                    ? "text-[#67B7FD] border-2 rounded-lg border-[#67B7FD] hover:border-[#4a7cae]"
                                    : "hover:text-[#78B4F0]"
                            }`}
                        >
                            {item.name}
                        </Link>
                    ))}
                </div>

                {/* Right Section (Language and Search Buttons) */}
                {/* {!isMobile && (
                    <div className="flex gap-4 items-center">
                        <div className="relative">
                            <div
                                onClick={toggleDropdown}
                                className="flex justify-center items-center bg-[#FFFFFF1A]/10 text-white hover:border-white px-5 py-2 hover:border-2 rounded-lg space-x-2 cursor-pointer"
                            >
                                <span>{language}</span>
                                {dropdownOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                            </div>

                            {dropdownOpen && (
                                <div className="absolute mt-2 w-full bg-[#FFFFFF1A]/10 text-white rounded-lg shadow-lg">
                                    <ul>
                                        <li className="px-5 py-2 hover:bg-[#FFFFFF33] cursor-pointer" onClick={() => handleLanguageChange("EN")}>
                                            EN
                                        </li>
                                        <li className="px-5 py-2 hover:bg-[#FFFFFF33] cursor-pointer" onClick={() => handleLanguageChange("ID")}>
                                            ID
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>

                        <div className={`relative flex items-center transition-all duration-300 ease-in-out ${searchActive ? "w-64" : "w-12"}`}>
                            <input
                                type="text"
                                className={`absolute  bg-[#FFFFFF1A]/0 h-full font-normal text-sm  text-white border-none outline-none transition-all duration-300 ease-in-out z-10 ${
                                    searchActive ? "opacity-100 w-2/3 h-full pl-4" : "opacity-0 w-0"
                                }`}
                                placeholder="Search..."
                            />
                            <button
                                onClick={toggleSearch}
                                className="flex items-center justify-end w-full  bg-[#FFFFFF1A]/10 text-white hover:border-white px-4 py-2 hover:border-2 rounded-lg transition-all duration-300 ease-in-out z-0"
                            >
                                <FaSearch />
                            </button>
                        </div>
                    </div>
                )} */}
            </div>
        </div>
    );
}
