import axios from "../app/api/Index";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../slice/AuthSlice";

const useFetchApiGet = () => {
    const token = useSelector(selectCurrentToken);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState([]);
    const [allowedPageIndex, setAllowedPageIndex] = useState([]);

    const fetchApiGet = async (url, mode) => {
        if (url !== "") {
            const option = {
                Authorization: `Bearer ${token}`,
            };
            setLoading(true);
            try {
                const response = await axios.get(url, option);
                if (response.status === 200 || response.status === 201) {
                    if (mode === "pagination"){
                        setDetailData({
                            total: response.data.total,
                            limit: response.data.limit,
                            page: response.data.page,
                        });
                        let pageInd = [];
                        for (let i = 1; i < response.data.total / response.data.limit + 1; i++) {
                            pageInd.push(i);
                        }
                        setAllowedPageIndex(pageInd);
                    }
                    setData(response.data.data);
                    setLoading(false);
                    return response;
                }
            } catch (error) {
                setLoading(false);
                throw error;
            }
        }
    };

    return { data, setData, detailData, allowedPageIndex, loading, fetchApiGet };
};

export default useFetchApiGet;
