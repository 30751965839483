import React, { useState } from "react";

export default function Paper({ paper, handleReadMore }) {
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    // Menangani perubahan checkbox tag
    const handleCheckboxChange = (tag) => {
        setSelectedTags((prevSelectedTags) => {
            if (prevSelectedTags.includes(tag)) {
                return prevSelectedTags.filter((t) => t !== tag);
            } else {
                return [...prevSelectedTags, tag];
            }
        });
    };

    // Menangani perubahan input pencarian
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    // Ambil semua tags unik
    const allTags = paper
        .map((item) => item.tags)
        .flat()
        .filter((value, index, self) => self.indexOf(value) === index);

    // Filter paper berdasarkan selectedTags dan searchQuery
    const filteredPaper = paper.filter((data) => {
        // Filter berdasarkan tag yang dipilih
        const matchesTags = selectedTags.length === 0 || data.tags.some((tag) => selectedTags.includes(tag));

        // Filter berdasarkan searchQuery di judul atau deskripsi
        const matchesSearchQuery =
            data.title.toLowerCase().includes(searchQuery) || (data.abstracts && data.abstracts.toLowerCase().includes(searchQuery));

        return matchesTags && matchesSearchQuery;
    });

    return (
        <div className="text-black h-full ">
            <div className="space-y-4 md:px-0 px-5">
                <h1 className="font-semibold text-[1.75rem]">Paper</h1>
                <p className="text-[#757575] xl:text-xl lg:text-lg md:text-base text-sm font-normal ">
                    We serve best insight for your industries and learn more about asset management solutions.
                </p>
            </div>
            {/* all content */}
            <div className="flex max-md:flex-col justify-start gap-6 w-full h-full text-black max-md:px-5">
                {/* tags */}
                <div className="lg:w-1/6 lg:flex-col flex justify-start items-start h-full w-full">
                    {paper.length === 0 ? (
                        <div
                            className="text-center text-gray-400 text-xl font-medium h-[27rem] flex justify-center items-center animate-pulse duration-700 ease-in"
                            data-aos="fade-up"
                        >
                            We're working behind the scenes to bring you great content. Check back soon!
                        </div>
                    ) : (
                        <div className="max-md:flex max-md:justify-center max-md:items-center md:px-0 md py-10 px-5">
                            <h1 className="max-md:hidden">Tags</h1>
                            <input
                                type="search"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className="mb-4 p-2 border border-gray-500/20 rounded-md text-sm font-light focus:border-gray-500/40"
                                placeholder="Search papers..."
                            />
                            {/* Dropdown for mobile */}
                            <div className="lg:hidden">
                                <select
                                    value={selectedTags}
                                    onChange={(e) => handleCheckboxChange(e.target.value)}
                                    className="mb-4 p-2 border border-gray-500/20 rounded-md text-sm font-light focus:border-gray-500/40 w-full"
                                >
                                    {allTags.map((tag, index) => (
                                        <option key={index} value={tag}>
                                            {tag}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* Checkbox list for larger screens */}
                            <div className="hidden lg:flex h-full lg:flex-col lg:justify-start lg:space-y-2 space-x-4 lg:space-x-0 lg:items-start justify-center items-center md:overflow-x-auto overflow-x-scroll w-full">
                                {allTags.map((tag, index) => (
                                    <div key={index} className="flex items-center space-x-3">
                                        <input
                                            type="checkbox"
                                            id={tag}
                                            className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                            checked={selectedTags.includes(tag)}
                                            onChange={() => handleCheckboxChange(tag)}
                                        />
                                        <label htmlFor={tag} className="text-gray-700 font-light">
                                            {tag}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                {/* content */}
                <div className="md:flex-1 bg-white w-full space-y-4 md:p-2 max-md:px-3">
                    {filteredPaper.length === 0 && paper.length !== 0 ? (
                        <div className="text-center text-gray-400 text-xl font-medium h-[27rem] flex justify-center items-center animate-pulse duration-700 ease-in">
                            No content available for the selected tags or search query.
                        </div>
                    ) : (
                        <div className="space-y-5">
                            {filteredPaper.map((data, index) => (
                                <div
                                    key={index}
                                    className="w-full shadow-xl rounded-xl hover:cursor-pointer ease-in-out duration-300 hover:translate-x-2 "
                                    onClick={() => handleReadMore(data)}
                                >
                                    <div className="p-5 md:text-lg md:space-y-8 space-y-3">
                                        <div className="space space-y-2">
                                            <h1 className="text-2xl md:text-3xl">{data.title}</h1>
                                            <p className="md:text-sm text-xs font-extralight">
                                                <span>Author: </span>
                                                {data.dateText}
                                            </p>
                                            <div className="flex space-x-6">
                                                <p className="md:text-sm text-xs font-light">
                                                    <span>Author: </span>
                                                    {data.created_by}
                                                </p>
                                                <p className="md:text-sm text-xs font-light">
                                                    <span>ISBN: </span>
                                                    {data.isbn}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="relative md:text-sm text-xs text-left font-light space-y-3">
                                            <p>Tags</p>
                                            <div className="flex flex-wrap justify-start space-x-2">
                                                {data.tags.map((tag, index) => (
                                                    <p key={index} className="px-3 py-1 bg-[#CCD6E4] rounded-full text-xs text-[#003478]">
                                                        {tag}
                                                    </p>
                                                ))}
                                            </div>
                                            <div className="absolute right-0 bottom-0 text-[#003478] font-semibold">Read More</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
