import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
import AOS from "aos";
import ListArticle from "../../../components/ListArticle";

export default function Article({ handleReadMore, articles }) {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
            offset: 150,
            easing: "ease-in-out",
        });
    }, []);

    return (
        <div className="text-black space-y-14 h-full ">
            <div className="space-y-4 md:px-0 px-5">
                <h1 className="font-semibold text-[1.75rem]" data-aos="fade-down">
                    Article
                </h1>
                <p className="text-[#757575] xl:text-xl lg:text-lg md:text-base text-sm font-normal " data-aos="fade-right">
                    We serve best insight for your industries and learn more about asset management solutions.
                </p>
            </div>
            {articles.length === 0 ? (
                <div
                    className="text-center text-gray-400 text-xl font-medium h-[27rem] flex justify-center items-center animate-pulse duration-700 ease-in"
                    data-aos="fade-up"
                >
                    We're working behind the scenes to bring you great content. Check back soon!
                </div>
            ) : (
                <ListArticle article={articles} handleReadMoreArticle={handleReadMore} />
            )}
        </div>
    );
}
