import React, { useEffect, useState } from "react";
import { useFetcher, useLocation, useNavigate } from "react-router-dom";
import AOS from "aos";
import { CEO_DETAILS } from "../../Data/data";
import Header from "../../components/Header";
import { FaArrowLeft, FaLinkedin, FaLinkedinIn } from "react-icons/fa";
import Breadcrumbs from "../../components/Breadcrumbs";
import pakchandra from "../../assets/kochan.png";
import { MdOutlineEmail } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import icondetail from "../../assets/icondetailceo.svg";
import Loading from "../../components/Loading";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

export default function DetailCeo() {
    const location = useLocation();
    const { name, role, summary, specialties, workExamples, imgSrc, imgBanner } = CEO_DETAILS; // Use the CEO_DETAILS directly


    const [isLoading, setIsLoading] = useState(false);
    const [isModalExpanded, setIsModalExpanded] = useState(false);

    const navigate = useNavigate();
    const handleOpenNewTabLinkedin = () => {
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
            window.open("https://www.linkedin.com/in/chandrasalim/", "_blank");
        }, 2000);
    };
    const toggleModal = () => {
        setIsModalExpanded(!isModalExpanded);
    };
    const handleBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
            duration: 1000,
            once: true,
            offset: 150,
            easing: "ease-in-out",
        });
    }, []);

    const breadcrumb = [
        { label: "Home", path: "/" },
        { label: "About Us", path: "/aboutus" },
        { label: "CEO Detail", path: "/aboutus/ceo-detail" },
    ];

    if (!name || !role || !summary || !imgSrc) {
        return (
            <div className="flex flex-col items-center p-10">
                <h1 className="text-4xl font-semibold">Data not available</h1>
            </div>
        );
    }


    return (
        <div className="h-full overflow-hidden">
            {isLoading ? (
                <Loading />
            ) : (
                <div>
                    <div className="max-md:hidden">
                        <Header
                            bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                            className="h-[5.3rem] max-md:h-[7rem] xl:px-32 md:px-14 px-10"
                        >
                            <div className="text-4xl space-y-1">
                                <div className="absolute w-full top-0 bg-gradient-to-r from-blue-950/100 to-blue-300/5 z-10"></div>
                                <div>{CEO_DETAILS ? CEO_DETAILS.title : "Service Not Found"}</div>
                            </div>
                        </Header>
                        <Breadcrumbs breadcrumbs={breadcrumb} />
                        <div className="flex flex-col items-center xl:px-32 lg:px-24 md:px-16 px-5  md:py-20 p-10 max-md:py-10 max-md:px-5  font-normal space-y-10">
                            <div className="bg-gradient-to-l from-[#ECF0FA] to-[#FBFCFF]  w-full h-[329px] drop-shadow-lg my-2 overflow-hidden flex justify-center items-center xl:px-20 relative ">
                                <div className="w-1/3  h-full">
                                    <img src={pakchandra} className="w-72 h-full" alt="chandra salim" />
                                </div>
                                <div className="flex-1 h-full px-5 flex flex-col justify-between py-5">
                                    <div className="space-y-3">
                                        <h1 className="text-5xl font-semibold">Chandra Salim</h1>
                                        <p className="font-medium text-xl">Ir., IPM, AER, M.Sc., CMRP, RCM Facilitator Lv. 2</p>
                                    </div>
                                    <div className="space-y-6">
                                        <div className="flex w-1/2 space-x-5" id="icon">
                                            <div className="hover:cursor-pointer hover:text-hover text-main" onClick={handleOpenNewTabLinkedin}>
                                                <FaLinkedinIn className="text-2xl " />
                                            </div>
                                            <a className="hover:cursor-pointer hover:text-hover text-main" href="mailto:chandra.salim@cliste.co.id">
                                                <MdOutlineEmail className="text-2xl " />
                                            </a>
                                            <a className="hover:cursor-pointer hover:text-hover text-main" href="tel:+6281219810869">
                                                <FiPhone className="text-2xl " />
                                            </a>
                                        </div>
                                        <div>
                                            <img src={icondetail} alt="cmrp smrp itb ansi iec lspmigas lsp migas" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <h1 className="font-bold md:text-xl" data-aos="fade-right">
                                        Summary
                                    </h1>
                                    {summary && summary.length > 0 ? (
                                        summary.map((data, index) => (
                                            <p key={index} className="lg:text-lg max-lg:text-base font-normal md:px-4 py-3" data-aos="fade-right">
                                                {data}
                                            </p>
                                        ))
                                    ) : (
                                        <li className="text-lg font-normal" data-aos="zoom-in">
                                            No work examples available.
                                        </li>
                                    )}
                                </div>

                                <h2 className="mt-8 md:text-xl font-semibold w-full" data-aos="fade-right">
                                    Examples of Work
                                </h2>
                                <ol className="mt-2 list-decimal md:px-10 px-5 space-y-4" data-aos="fade-right">
                                    {workExamples && workExamples.length > 0 ? (
                                        workExamples.map((example, index) => (
                                            <li key={index} className="lg:text-lg max-lg:text-base font-normal md:px-4">
                                                {example}
                                            </li>
                                        ))
                                    ) : (
                                        <li className="text-lg font-normal">No work examples available.</li>
                                    )}
                                </ol>
                            </div>
                        </div>
                    </div>

                    {/* MODAL KONTEN */}
                    <div className="fixed inset-0 bg-[#EDF1FA] z-50 overflow-hidden md:hidden ">
                        <button className="text-base flex justify-center items-center space-x-4 fixed left-5 top-5 " onClick={handleBack}>
                            <FaArrowLeft className="text-xl font-semibold animate-fade-left animate-fade-left" />
                        </button>
                        <div className="relative -bottom-10">
                            <img src={pakchandra} className="w-full object-cover bottom-0" alt="chandra salim" />
                        </div>
                        <div
                            className={`fixed -bottom-5 left-0 w-full transition-all duration-500 bg-gradient-to-b from-[#2B568E] to-[#003278]  ${
                                isModalExpanded
                                    ? "h-full overflow-auto scale-100"
                                    : "h-1/2 rounded-t-[18px] flex justify-center items-center scale-95 -bottom-5"
                            }`}
                        >
                            <div className=" h-full  ">
                                <div className="font-normal h-full ">
                                    <div className="flex justify-center items-center h-7 py-5   " onClick={toggleModal}>
                                        <button className="text-text_30 focus:outline-none text-[45px] font-bold">
                                            {isModalExpanded ? (
                                                <IoIosArrowDown className="animate-bounce duration-300 transition-transform pt-5" />
                                            ) : (
                                                // <IoIosArrowUp className="animate-pulse duration-300 rotate-180" />

                                                <IoIosArrowUp className="animate-bounce duration-300 transition-transform pt-5" />
                                            )}
                                        </button>
                                    </div>
                                    <div className=" w-full h-[329px] drop-shadow-lg overflow-hidden flex justify-center items-center xl:px-20 ">
                                        <div className="flex-1 h-full px-5 flex flex-col justify-between py-5">
                                            <div className="space-y-3 text-text_30">
                                                <h1 className="text-5xl font-semibold">Chandra Salim</h1>
                                                <p className="font-normal text-sm">Ir., IPM, AER, M.Sc., CMRP, RCM Facilitator Lv. 2</p>
                                            </div>
                                            <div className="bg-[#D9D9D9] px-2 py-5">
                                                <img src={icondetail} alt="cmrp smrp itb ansi iec lspmigas lsp migas" />
                                            </div>
                                            <div className="space-y-6">
                                                <div className="flex w-1/2 space-x-6" id="icon">
                                                    <div className="hover:cursor-pointer " onClick={handleOpenNewTabLinkedin}>
                                                        <FaLinkedinIn className="text-2xl  hover:text-text_70 text-text_30 " />
                                                    </div>
                                                    <a className="hover:cursor-pointer " href="mailto:chandra.salim@cliste.co.id">
                                                        <MdOutlineEmail className="text-2xl  hover:text-text_70 text-text_30" />
                                                    </a>
                                                    <a className="hover:cursor-pointer " href="tel:+6281219810869">
                                                        <FiPhone className="text-2xl hover:text-text_70 text-text_30 " />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h-full px-5 text-[#FDFDFD]">
                                        <div className="py-5">
                                            <h1 className="font-bold md:text-xl">Summary</h1>
                                            {summary && summary.length > 0 ? (
                                                summary.map((data, index) => (
                                                    <p key={index} className=" font-light md:px-4 py-3">
                                                        {data}
                                                    </p>
                                                ))
                                            ) : (
                                                <li className="text-lg font-normal" data-aos="zoom-in">
                                                    No work examples available.
                                                </li>
                                            )}
                                        </div>
                                        <div className="py-5">
                                            <h2 className="mt-8 md:text-xl font-semibold w-full">Examples of Work</h2>
                                            <ol className="mt-2 list-decimal md:px-10 px-5 space-y-5">
                                                {workExamples && workExamples.length > 0 ? (
                                                    workExamples.map((example, index) => (
                                                        <li key={index} className=" font-light md:px-4">
                                                            {example}
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li className="text-lg font-normal">No work examples available.</li>
                                                )}
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
