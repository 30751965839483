import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/Header";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AOS from "aos";
import useFetchApiGet from "../../../Hooks/useFetchApiGet";
import FormatDateToText from "../../../Constant/FormatDateToText";
import "quill/dist/quill.snow.css";
import ListArticle from "../../../components/ListArticle";

export default function ArticleDetail() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [articleDetail, setArticleDetail] = useState({});

    const { fetchApiGet: fetchApiGetArticleDetail } = useFetchApiGet();
    const { data: otherArticles, setData: setOtherArticles, fetchApiGet: fetchApiGetArticle } = useFetchApiGet();

    const onGetArticleDetail = async (id) => {
        try {
            const response = await fetchApiGetArticleDetail(`/article/detail/${id}`);
            if (response.status === 200) {
                const articleData = response.data.data;

                const formattedArticleData = {
                    ...articleData,
                    dateText: FormatDateToText(articleData.date),
                };

                setArticleDetail(formattedArticleData);

                const getReadTime = (summary) => {
                    const wordCount = summary.join(" ").split(" ").length;
                    const wordsPerMinute = 225;
                    const timeInMinutes = Math.ceil(wordCount / wordsPerMinute);
                    return `${timeInMinutes} min read`;
                };
                const allArticlesResponse = await fetchApiGetArticle(`/article/list/1/100/all`);
                if (allArticlesResponse.status === 200) {
                    const allArticles = allArticlesResponse.data.data;
                    const currentDate = new Date();

                    const filteredOtherArticles = allArticles
                        .filter((article) => article.id !== id)
                        .map((article) => ({
                            ...article,
                            timeRead: getReadTime(article.summary),
                            dateText: FormatDateToText(article.date),
                            parsedDate: new Date(article.date),
                        }))
                        .filter((item) => item.parsedDate.getTime() <= currentDate.getTime())
                        .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());

                    setOtherArticles(filteredOtherArticles);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleReadMore = (article) => {
        navigate(`/ourInsight/article/${article.id}`, { state: { article } });
    };

    const breadcrumb = [
        { label: "Home", path: "/" },
        { label: "Our Insight", path: "/ourInsight" },
        { label: articleDetail.title, path: `/ourInsight/article/${id}` },
    ];
    useEffect(() => {
        AOS.init({
            duration: 800,
            once: true,
            offset: 150,
            easing: "ease-in-out",
        });
    }, []);
    useEffect(() => {
        onGetArticleDetail(id);
    }, [id]);

    return (
        <div className="h-full w-full overflow-hidden">
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="md:h-[6rem] max-md:h-[6rem] xl:px-32 md:px-14 px-10 text-white hover:text-blue-400 "
            />

            <div className="relative flex mx-auto flex-col w-2/3 items-center xl:px-32  md:py-20 p-5 font-normal bg-[#F6FBFF]">
                <Breadcrumbs breadcrumbs={breadcrumb} />
                {articleDetail ? (
                    <div className="w-full text-[#676767] ">
                        <div className="header md:space-y-10 space-y-5 ">
                            <div>
                                <img
                                    className="w-full md:h-[400px] h-1/2 object-cover rounded-lg"
                                    src={`data:image/png;base64,${articleDetail.image}`}
                                    alt=""
                                    data-aos="fade-down"
                                />
                            </div>
                            <div className="font-normal space-y-5 text bg-[#fefefe] shadow-md drop-shadow-md px-5 py-10 md:rounded-xl">
                                <div className="px-4">
                                    {articleDetail.title && articleDetail.title.length > 0 && (
                                        <h1 className="md:text-3xl font-semibold text-[#2a5c9e]" data-aos="fade-down">
                                            {articleDetail.title}
                                        </h1>
                                    )}
                                </div>
                                <div className="text-[#6c6c6c] px-4" data-aos="fade-right">
                                    {articleDetail.dateText && (
                                        <div className="flex space-x-2">
                                            <p className="">Date :</p>
                                            <p className="">{articleDetail.dateText}</p>
                                        </div>
                                    )}
                                    {articleDetail.author && (
                                        <div className="flex space-x-2">
                                            <p className="">Atuhor :</p>
                                            <p className="">{articleDetail.author}</p>
                                        </div>
                                    )}
                                    {articleDetail.timeRead && <p className="">{articleDetail.timeRead}</p>}
                                </div>
                                <main className="overflow-hidden">
                                    {articleDetail.summary && (
                                        <p className="ql-editor" data-aos="fade-left" dangerouslySetInnerHTML={{ __html: articleDetail.summary }}></p>
                                    )}
                                </main>
                            </div>
                        </div>
                    </div>
                ) : (
                    <p>Service Detail not found.</p>
                )}
            </div>
            <div className="w-full flex justify-center xl:px-32 px-5 bg-[#F6FBFF] py-10">
                <ListArticle article={otherArticles} handleReadMoreArticle={handleReadMore} />
            </div>
        </div>
    );
}
