export default function FormatDateToText(input) {
    const date = new Date(input);
  
    if (isNaN(date.getTime())) {
      // throw new Error("Invalid date");
      return ""
    }
  
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

  
    // return `${month} ${day}, ${year}`;
    return `${month}, ${day}`;
  }