import React from "react";
import Header from "../../../components/Header";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { vacancies } from "../../../Data/data";
import { GoPeople } from "react-icons/go";
import { IoBagRemoveOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";

export default function OurVacanciesDetail() {
    const { id } = useParams();

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const handleApply = () => {
        navigate(`/Ourvacancies/apply/${vacancy.id}`);
    };
    const vacancy = vacancies.find((vac) => vac.id === parseInt(id));
    return (
        <div>
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="md:h-[25rem] h-screen xl:px-32 md:px-14 px-10 max-md:hidden "
                position="end"
            >
                <div className="text-4xl ">
                    <div>
                        <p className="text-sm font-normal">Work with us</p>
                        <div>{vacancy.title}</div>
                    </div>
                </div>
            </Header>
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="max-md:h-[7rem] xl:px-32 md:px-14 px-10 md:hidden"
                position="center"
            >
                {/* <button className="text-base flex justify-center items-center space-x-4 " onClick={handleBack}>
                    <FaArrowLeft />
                    <h1>Back</h1>
                </button> */}
            </Header>
            <div className="relative bg-white xl:px-32 md:px-10 px-5 xl:py-20 font-normal space-y-10 pb-20 my-10">
                <div className="xl:text-4xl md:text-3xl text-3xl space-y-1 font-semibold md:hidden ">
                    <p className="text-base font-normal">Work with us</p>
                    <div>{vacancy.title}</div>
                </div>
                <div className="flex xl:space-x-10 space-x-4 text-sm flex-1">
                    <div className="flex space-x-1   justify-center items-center">
                        <GoPeople />
                        <h1>{vacancy.workingMode}</h1>
                    </div>
                    <div className="flex space-x-1   justify-center items-center">
                        <IoBagRemoveOutline />
                        <h1>{vacancy.level}</h1>
                    </div>
                    <div className="flex space-x-1   justify-center items-center">
                        <CiLocationOn />
                        <h1>{vacancy.location}</h1>
                    </div>
                </div>

                <div>{vacancy.description}</div>
                <div className="space-y-5">
                    <div className="font-semibold">Job Description</div>
                    <ul className="space-y-2 px-5">
                        {vacancy.jobdesk.map((data, index) => (
                            <li key={index} className="list-disc font-normal">
                                {data}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="space-y-5">
                    <div className="font-semibold">Requirement</div>
                    <ul className="space-y-2 px-5">
                        {vacancy.requirement.map((data, index) => (
                            <li key={index} className="list-disc font-normal">
                                {data}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="space-y-5">
                    <div className="font-semibold">Benefit</div>
                    <ul className="space-y-2 px-5">
                        {vacancy.benefit.map((data, index) => (
                            <li key={index} className="list-disc font-normal">
                                {data}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="absolute md:bottom-5 md:left-28 max-md:left-4 text-sm text-[#fefefe] px-5 ">
                    <button className="bg-[#003478] px-5 py-2 hover:bg-[#012a5f]" onClick={handleApply}>
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
}
